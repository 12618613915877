export default {
  props: {
    data: Object | Array
  },
  data() {
    return {};
  },
  methods: {},

  mounted() {}
};
