const moment = require('moment');
const Highcharts = require('highcharts');

export default {
  props: {
    graph: Object,
    durationGroup: String,
    duration: String
  },
  data() {
    return {
      barChartOptions: null,
      startTime: null,
      endTime: null,
      groupByDuration: null,
      chartObj: null,

      // Graph Variables
      pageNo: 1,
      pageSize: 10,
      graphData: null,
      categories: null,
      datasets: null
    };
  },
  watch: {
    watchProperty() {
      this.drawBarGraph(this.graph);
    }
  },
  computed: {
    watchProperty() {
      return `${this.durationGroup}|${this.duration}`;
    }
  },
  methods: {
    prepareDataSets(graph) {
      this.graphData = graph.data;

      // this.graphData = null;
      if (this.graphData === null) {
        this.barChartOptions = null;
        return;
      }

      if (graph.durationType == 'exact') {
        this.startTime = moment(graph.startTime);
        this.endTime = moment(graph.endTime);
      } else if (graph.durationType == 'latest') {
        this.timingFilter = graph.duration;
        this.startTime = moment().subtract(this.timingFilter, 'minutes');
        this.endTime = moment();
      } else {
        throw new Error('Invalid duration type.');
      }

      this.groupByDuration = graph.durationGroup;

      // Build categories
      this.categories = [];
      this.graphData.forEach((xy) => {
        this.categories.push(xy.x);
      });

      // Build dataset for each category
      this.datasets = {};
      this.categories.forEach((category) => {
        //For given category, put 0 or value in dataset
        this.graphData.forEach((currentEntry) => {
          if (!this.datasets[category]) {
            this.datasets[category] = {
              name: category,
              data: []
            };
          }

          //Put current category's value if found.
          if (currentEntry.x == category) {
            this.datasets[category].data.push(parseInt(currentEntry.y));
          } else {
            this.datasets[category].data.push(0);
          }
        });
      });
      this.datasets = Object.values(this.datasets);
    },

    onPageChange() {
      try {
        if (this.graphData === null) {
          this.barChartOptions = null;
          return;
        }

        let start = (this.pageNo - 1) * this.pageSize;
        let end = start + 10;
        // console.log('pagination', this.pageNo, this.pageSize, this.datasets.length);
        // console.log('strat, end', start, end);
        let categories = JSON.parse(JSON.stringify(this.categories.slice(start, end)));
        // console.log('categories', categories);
        let datasets = JSON.parse(JSON.stringify(this.datasets.slice(start, end)));
        // console.log('datasets', datasets);

        for (let i = 0; i < datasets.length; i++) {
          datasets[i].data = datasets[i].data.slice(start, end);
        }

        let chartOptions = {
          chart: {
            type: 'column',
            height: 200
          },
          title: {
            text: null
          },
          xAxis: {
            type: 'category',
            categories: categories
          },
          yAxis: {
            min: 0,
            title: {
              text: null
            },
            stackLabels: {
              style: {
                color: '#555'
              },
              enabled: true
            }
          },
          legend: {
            enabled: false
          },
          tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{point.y}'
          },
          plotOptions: {
            column: {
              stacking: 'normal',
              dataLabels: {
                enabled: false,
                color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
              }
            }
          },
          series: datasets
        };
        if (!this.barChartOptions) {
          this.barChartOptions = chartOptions;
        } else {
          let chart = this.$refs.barGraph.chart;
          while (chart.series.length > 0) {
            chart.series[0].remove(false);
          }
          chart.xAxis[0].setCategories(categories, false);
          for (let i = 0; i < datasets.length; i++) {
            chart.addSeries(datasets[i], false);
          }
          chart.redraw();
        }
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something is wrong, please contact support.');
      }
    },

    drawBarGraph(graph) {
      try {
        this.prepareDataSets(graph);

        this.pageNo = 1;
        this.onPageChange();
      } catch (e) {
        this.reportError(e);
        this.errorToast('Something is wrong, please contact support.');
      }
    }
  },

  mounted() {
    this.drawBarGraph(this.graph);
  }
};
