<template>
  <div class="fourColumnDashboardComponent">
    <el-row>
      <el-col :span="6">
        <div class="numbers">{{ getNumberWithComma(data[0].value ? data[0].value : 0) }}</div>
        <div class="title">{{ data[0].title }}</div>
      </el-col>
      <el-col :span="6">
        <div class="numbers">{{ getNumberWithComma(data[1].value ? data[1].value : 0) }}</div>
        <div class="title">{{ data[1].title }}</div>
      </el-col>
      <el-col :span="6">
        <div class="numbers">{{ getNumberWithComma(data[2].title ? data[2].value : 0) }}</div>
        <div class="title">{{ data[2].title }}</div>
      </el-col>
      <el-col :span="6">
        <div class="numbers">{{ getNumberWithComma(data[3].value ? data[3].value : 0) }}</div>
        <div class="title">{{ data[3].title }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" src="./fourColumnNumber.scss"></style>

<script>
import FourColumnNumberComponent from './fourColumnNumberComponent';
export default FourColumnNumberComponent;
</script>
