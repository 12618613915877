<template>
  <div id="barChartGraph">
    <div class="barChart" v-if="barChartOptions">
      <highcharts class="eventsChart" ref="barGraph" :options="barChartOptions"></highcharts>

      <div class="chartPagination">
        <el-pagination small background @current-change="onPageChange" :current-page.sync="pageNo" :page-size="pageSize" layout="prev, pager, next" :total="datasets.length"></el-pagination>
      </div>
    </div>
    <div v-else class="noDatafound" style="text-align: center; padding-top: 15vh; opacity: 0.5">
      <!-- <img src="/static/img/segments_icon.png" style="width: 80px;" /> -->
      <br />
      No data found
    </div>
  </div>
</template>

<style lang="scss" src="./barGraph.scss"></style>

<script>
import BarGraph from './barGraphComponent';
export default BarGraph;
</script>
